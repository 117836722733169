.nav{
    grid-row: 2/3;
    grid-column: 1/-1;
    justify-self: center;
    padding-top: 30px;

    display: flex;
    gap: 16px;

    &__border{
        display: flex;
        background: linear-gradient(315deg,#DCDFEA, #DBD2E1);
        border-radius: 12px;
        padding: 2px;
        filter: grayscale(0.5);
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &__option{
            background-color: #F5F5F5;
            border-radius: 11px;
            display: inline-flex;
            padding: 8px 12px 8px 12px;
            align-items: center;
            & a{
                align-items: center;
                display: flex;
                & span{
                    margin-left: 8px;
                    font-size: 14px;
                }
            }
        }
    }
    &__border:hover{
        background: linear-gradient(315deg, #4A6FF3, #B580DE);
        transform: translateY(-1px);
        box-shadow: 0px 4px 24px -18px rgba(32, 32, 32, 1);  
        filter: grayscale(0);      
        transition: all 0.2s ease-in-out;
    }

    &__option{
        background-color: #F5F5F5;
        border-radius: 12px;
        border: solid 2px #DCDFEA;
        display: inline-flex;
        padding: 8px 12px 8px 12px;
        align-items: center;
        cursor: pointer;
        filter: grayscale(0.5);
        transition: all 0.2s ease-in-out;

        & span{
            margin-left: 8px;
            font-size: 12px;
        }
    }
    &__option:hover{
        box-shadow: 0px 4px 24px -18px rgba(32, 32, 32, 1);
        background: #f7f7f7;
        transition: all 0.2s ease-in-out;
        transform: translateY(-1px);
        filter: grayscale(0);
    }
}



@media only screen and (max-width: 576px){
    .nav{
        display: block;
        width: 100%;
        &__border{
            margin: 12px 0 12px 0;
            &__option{
                width: 100%;
            }
        }
    }
}