.container{
    display: grid;
    grid-template-columns: 1fr 1170px 1fr;
    grid-template-rows: [main-start] 90vh [main-end projects-start] min-content [projects-end];
}

.main{
    grid-column: 2/3;
    grid-row: main;
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    grid-template-rows: repeat(2, min-content);
    align-self: center;
}
.projects{
    grid-column: 2/3;
    grid-row: projects;
}


@media only screen and (max-width: 1200px){
    .container{
        grid-template-columns: 1rem 1fr 1rem;
    }
}