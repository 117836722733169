
@import '../scss/base/_normalize.scss';
@import '../scss/base/_reset.scss';
@import '../scss/layout/_grid.scss';
@import '../scss/utils/_variables.scss';

@import '../scss/components/_hero.scss';
@import '../scss/components/_nav.scss';


@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');

html{
    font-size: 16px;
}

body{
    background-color: $backgroundGrey;
    font-family: 'Rubik', sans-serif;
}

a{
    color: $textBlack;
    text-decoration: none;
}
a:hover{
    color:$linkHoverGrey;
}

[data-tooltip]{
    position: relative;
    text-decoration: none;
    &:hover,
    &:focus {
      &:before {
        opacity: 1;
        transform: translate(-50%,-10px);
      }
      &:after {
        opacity: 1;
        transform: translate(-50%,-10px) rotate(45deg);
      }
    }
    &:before {
      position: absolute;
      z-index: 2;
      white-space: nowrap;
      content: attr(data-tooltip);
      bottom: 100%;
      left: 50%;
      padding: 0.5em 1em;
      color: white;
      font-size: 0.8em;
      line-height: 1.7em;
      background-color: rgb(54, 54, 54);
      transition: transform 0.2s ease-out;
      opacity: 0;
      transform: translate(-50%,0);
      pointer-events: none;
      border-radius: 4px;
      box-shadow: 0 0 20px rgba(0,0,0,0.2);
    }
    &:after {
      position: absolute;
      z-index: 3;
      display: block;
      bottom: 100%;
      left: 50%;
      content: '';
      width: 10px;
      height: 10px;
      background-color: rgb(54, 54, 54);
      margin-bottom: -4px;
      transform: translate(-50%,0) rotate(45deg);
      transition: transform 0.2s ease-out;
      opacity: 0;
      pointer-events: none;
    }
    &.break:before {
      white-space: normal;
      width: 360px;
    }
  
  }