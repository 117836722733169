.hero{
    grid-row: 1/2;
    grid-column: 2/3;
    &__me{
        display: flex;
        justify-content: center;

        & img{
            width: 250px;
            border-radius: 180px;
        }
    }
    &__logo{
        display: flex;
        justify-content: center;
        padding: 28px 0 28px 0;

        & img{
            width: 280px;
        }
    }
}